<template>
    <div class="container" v-loading="true"></div>
</template>
<script>
export default {
    created() {
        setTimeout(() => {
            this.$router.push('/')
        }, 1000)
    },
}
</script>
<style lang="less" scoped>
.container {
    width: 100vw;
    height: 100vh;
}
</style>
